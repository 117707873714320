export const QUERYSTRING = 'querystring';
export const UNAUTHORIZED = 'unauthorized';
export const FORBIDDEN = 'forbidden';
export const ERRORREQUIRED = 'This field is required';
export const ERRORNUMBERSONLY = 'Only numbers are allowed';
export const NOTAPPLICABLE = 'N/A';
export const NONE = 'None';
export const PROFILEAUTHOR = 0;
export const PROFILESUBMITTED = 1;
export const PROFILEREVIEW = 2;
export const PROFILELIVE = 3;
export const PROFILEPENDING = 10;
export const YOUTUBEEMBED = 'https://www.youtube.com/embed/';
export const MAXSESSIONTIME = 172800000;
export const REMINDERTIME = 172740000;
export const MAXINACTIVITYTIME = 59;