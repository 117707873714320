import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class StorageService {

	private isLocalStorage: boolean;

	constructor() {
		this.isLocalStorage = !!window.localStorage;
	}

	set(key: string, value: string): void {

		if (this.isLocalStorage) {
			window.localStorage.setItem(key, value);
		} else {
			document.cookie = key + '=' + value + '; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT';
		}

	}

	get(key: string): string {

		if (this.isLocalStorage) {
			return window.localStorage.getItem(key);
		} else {

			const name = key + '=';
			const ca = document.cookie.split(';');

			for (let i = 0; i < ca.length; i++) {

				let c = ca[i];

				while (c.charAt(0) === ' ') {
					c = c.substring(1);
				}

				if (c.indexOf(name) === 0) {
					return c.substring(name.length, c.length);
				}

			}

			return null;
		}

	}

	remove(key: string): void {

		if (this.isLocalStorage) {
			window.localStorage.removeItem(key);
		} else {
			document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
		}

	}

	clear(): void {

		if (this.isLocalStorage) {
			window.localStorage.clear();
		} else {

			const cookies = ['access', 'address', 'getStartedMessage', 'lat', 'lng', 'refresh', 'ctnReminder', 'emailReminder'];

			cookies.forEach((cookie: string, i: number) => {
				this.remove(cookie);
			});

		}

	}

}
