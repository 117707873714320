import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StorageService } from './storage.service';
import { RouteService } from './route.service';
import { HeartbeatService } from './heartbeat.service';

@Injectable({
    providedIn: 'root'
})
export class AppstateService {

    private _isLoggedIn: boolean;
    private _accountInfo: any;

    constructor(public platform: Platform,
        public storageService: StorageService,
        public routeService: RouteService,
        public heartbeatService: HeartbeatService) {

        this._isLoggedIn = false;
        this._accountInfo = null;

    }

    public get isLoggedIn(): boolean {
        return this._isLoggedIn;
    }

    public set isLoggedIn(value: boolean) {
        this._isLoggedIn = value;
    }

    public get accountInfo(): any {
        return this._accountInfo;
    }

    public set accountInfo(value: any) {
        this._accountInfo = value;
    }

    logout(): void {

        this._isLoggedIn = false;

        this.heartbeatService.clear();

        this.storageService.remove('access');

        this.routeService.go('login');

    }

}
