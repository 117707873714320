import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { StartupService } from 'src/app/services/startup.service';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent {

	constructor(private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		public startupService: StartupService) {
		this.initializeApp();
	}

	initializeApp() {

		this.platform.ready().then(() => {

			this.statusBar.styleDefault();
			this.splashScreen.hide();
			this.startupService.setContent();

		});

	}
}
