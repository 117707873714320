import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class LoadingService {

	private loading: any;
	private isShowing: boolean;

	constructor(public loadingController: LoadingController) {

		this.loading = null;
		this.isShowing = false;

	}

	async showSpinner(label?: string) {

		if (!this.isShowing) {

			this.isShowing = true;

			this.loading = await this.loadingController.create({
				message: (!!label) ? label : 'Please wait...'
			});

			this.loading.onDidDismiss(() => {
				this.isShowing = false;
			});

			return await this.loading.present();

		}

	}

	hideSpinner() {

		if (!!this.loading) {

			this.loading.dismiss().then(() => {
				this.loading = null;
				this.isShowing = false;
			}, () => {
				this.loading = null;
				this.isShowing = false;
			});

		}

	}

}
