import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-timer',
	templateUrl: './timer.page.html',
	styleUrls: ['./timer.page.scss'],
})
export class TimerPage implements OnInit {

	private title: string;

	constructor() {
		this.title = 'Inactivity Warning';
	}

	ngOnInit() {
	}

}
