import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ObservableCountService {

	public count: number;

	constructor() {
		this.count = 0;
	}

	increment(): void {
		this.count++;
	}

	decrement(): void {
		this.count--;
	}

	reset(): void {
		this.count = 0;
	}

	getCount(): number {
		return this.count;
	}

}
