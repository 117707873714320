import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppstateService } from 'src/app/services/appstate.service';
import { LoadingService } from 'src/app/services/loading.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StartupService {

    private content: any;
    private contentLoaded: boolean;
    private contentSubject: BehaviorSubject<boolean>;

    constructor(public httpService: HttpService,
        public storageService: StorageService,
        public appStateService: AppstateService,
        public loadingService: LoadingService) {

        this.content = {};
        this.contentLoaded = false;
        this.contentSubject = new BehaviorSubject<boolean>(false);

    }

    checkLogin(): void {

        if (!!this.storageService.get('access')) {

            this.httpService.request('post', 'checkLogin', {}, true, true).subscribe((res: any) => {

                if (!!res.value.access) {
                    this.storageService.set('access', res.value.access);
                }

            }, (err: any) => {
                this.storageService.remove('access');
            });

        }

    }

    setContent(): void {

        this.httpService.requestStaticJson('content.json', true, true).subscribe((res: any) => {

            this.content = res.body;
            this.contentLoaded = true;

            this.loadingService.hideSpinner();
            this.httpService.hideSpinner();

            this.contentSubject.next(true);

        }, (err: any) => {

            this.loadingService.hideSpinner();
            this.httpService.hideSpinner();

            this.contentSubject.next(true);

        });

    }

    getContent(category?: string, key?: string): any {

        if (!!category) {

            if (!this.content[category]) {
                return {};
            } else {

                if (!!key) {
                    return (!!this.content[category][key] ? this.content[category][key] : {});
                } else {
                    return this.content[category];
                }

            }

        } else {
            return this.content;
        }

    }

    getStartupComplete(): BehaviorSubject<boolean> {
        return this.contentSubject;
    }

}
