import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpService } from '../services/http.service';
import { HeartbeatService } from './heartbeat.service';

@Injectable({
    providedIn: 'root'
})
export class AuthguardService implements CanActivate {

    constructor(public httpService: HttpService,
        public heartbeatService: HeartbeatService) { }

    canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {

        this.heartbeatService.beat();

        return this.httpService.validateLogin(state.url);

    }

}
