import { NgModule } from '@angular/core';
import { AuthguardService } from './services/authguard.service';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: 'home',
		canActivate: [AuthguardService],
		loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
	},
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full'
	},
	{
		path: 'location',
		canActivate: [AuthguardService],
		loadChildren: () => import('./location/location.module').then(m => m.LocationPageModule)
	},
	{
		path: 'candidates',
		canActivate: [AuthguardService],
		loadChildren: () => import('./candidates/candidates.module').then(m => m.CandidatesPageModule)
	},
	{
		path: 'candidates/:id',
		canActivate: [AuthguardService],
		loadChildren: () => import('./candidate-detail/candidate-detail.module').then(m => m.CandidateDetailPageModule)
	},
	{
		path: 'party-selection',
		loadChildren: () => import('./modals/party-selection/party-selection.module').then(m => m.PartySelectionPageModule)
	},
	{
		path: 'confirm',
		loadChildren: () => import('./modals/confirm/confirm.module').then(m => m.ConfirmPageModule)
	},
	{
		path: 'payment-method',
		loadChildren: () => import('./modals/payment-method/payment-method.module').then(m => m.PaymentMethodPageModule)
	},
	{
		path: 'issues',
		canActivate: [AuthguardService],
		loadChildren: () => import('./issues/issues.module').then(m => m.IssuesPageModule)
	},
	{
		path: 'issues/:id',
		canActivate: [AuthguardService],
		loadChildren: () => import('./issue-detail/issue-detail.module').then(m => m.IssueDetailPageModule)
	},
	{
		path: 'offices',
		canActivate: [AuthguardService],
		loadChildren: () => import('./offices/offices.module').then(m => m.OfficesPageModule)
	},
	{
		path: 'offices/:id',
		canActivate: [AuthguardService],
		loadChildren: () => import('./office-detail/office-detail.module').then(m => m.OfficeDetailPageModule)
	},
	{
		path: 'office-selection',
		loadChildren: () => import('./modals/office-selection/office-selection.module').then(m => m.OfficeSelectionPageModule)
	},
	{
		path: 'lookup',
		loadChildren: () => import('./modals/lookup/lookup.module').then(m => m.LookupPageModule)
	},
	{
		path: 'locations',
		canActivate: [AuthguardService],
		loadChildren: () => import('./locations/locations.module').then(m => m.LocationsPageModule)
	},
	{
		path: 'locations/:id',
		canActivate: [AuthguardService],
		loadChildren: () => import('./location-detail/location-detail.module').then(m => m.LocationDetailPageModule)
	},
	{
		path: 'parties',
		canActivate: [AuthguardService],
		loadChildren: () => import('./parties/parties.module').then(m => m.PartiesPageModule)
	},
	{
		path: 'parties/:id',
		canActivate: [AuthguardService],
		loadChildren: () => import('./party-detail/party-detail.module').then(m => m.PartyDetailPageModule)
	},
	{
		path: 'elections',
		canActivate: [AuthguardService],
		loadChildren: () => import('./elections/elections.module').then(m => m.ElectionsPageModule)
	},
	{
		path: 'elections/:id',
		canActivate: [AuthguardService],
		loadChildren: () => import('./election-detail/election-detail.module').then(m => m.ElectionDetailPageModule)
	},
	{
		path: 'ballot-items',
		canActivate: [AuthguardService],
		loadChildren: () => import('./ballot-items/ballot-items.module').then(m => m.BallotItemsPageModule)
	},
	{
		path: 'ballot-items/:id',
		canActivate: [AuthguardService],
		loadChildren: () => import('./ballot-item-detail/ballot-item-detail.module').then(m => m.BallotItemDetailPageModule)
	},
	{
		path: 'ballots',
		canActivate: [AuthguardService],
		loadChildren: () => import('./ballots/ballots.module').then(m => m.BallotsPageModule)
	},
	{
		path: 'ballots/:id',
		canActivate: [AuthguardService],
		loadChildren: () => import('./ballot-detail/ballot-detail.module').then(m => m.BallotDetailPageModule)
	},
	{
		path: 'events',
		canActivate: [AuthguardService],
		loadChildren: () => import('./events/events.module').then(m => m.EventsPageModule)
	},
	{
		path: 'events/:id',
		canActivate: [AuthguardService],
		loadChildren: () => import('./event-detail/event-detail.module').then(m => m.EventDetailPageModule)
	},
	{
		path: 'bills',
		canActivate: [AuthguardService],
		loadChildren: () => import('./bills/bills.module').then(m => m.BillsPageModule)
	},
	{
		path: 'bills/:id',
		canActivate: [AuthguardService],
		loadChildren: () => import('./bill-detail/bill-detail.module').then(m => m.BillDetailPageModule)
	},
	{
		path: 'accounts',
		canActivate: [AuthguardService],
		loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsPageModule)
	},
	{
		path: 'accounts/:id',
		canActivate: [AuthguardService],
		loadChildren: () => import('./account-detail/account-detail.module').then(m => m.AccountDetailPageModule)
	},
	{
		path: 'more-elections',
		loadChildren: () => import('./modals/more-elections/more-elections.module').then(m => m.MoreElectionsPageModule)
	},
	{
		path: 'login',
		loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
	},
  {
    path: 'timer',
    loadChildren: () => import('./modals/timer/timer.module').then( m => m.TimerPageModule)
  },
  {
    path: 'delete-item',
    loadChildren: () => import('./modals/delete-item/delete-item.module').then( m => m.DeleteItemPageModule)
  },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
