import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { RouteService } from './route.service';
import { StorageService } from './storage.service';
import { TimerPage } from 'src/app/modals/timer/timer.page';
import { MAXSESSIONTIME, REMINDERTIME} from 'src/app/consts';

declare var window: Window;

@Injectable({
    providedIn: 'root'
})
export class HeartbeatService {

    private logoutTimer: any;
    private reminderTimer: any;

    constructor(public alertController: AlertController,
        public modalController: ModalController,
        public routeService: RouteService,
        public storageService: StorageService) {

        this.logoutTimer = null;
        this.reminderTimer = null;

    }

    beat(): void {

        this.clear();

        this.logoutTimer = window.setTimeout(() => {

            this.clear();
            
            this.modalController.dismiss();

            this.storageService.remove('access');
            
            this.routeService.go('login');
        
        }, MAXSESSIONTIME);

        this.reminderTimer = window.setTimeout(() => {
            this.showReminder().then(() => { }, () => { });
        }, REMINDERTIME);

    }

    clear(): void {

        window.clearTimeout(this.logoutTimer);
        window.clearTimeout(this.reminderTimer);

    }

    private async showReminder() {

        const modal = await this.modalController.create({
            component: TimerPage,
            backdropDismiss: false
        });

        modal.onDidDismiss().then((data: any) => {

            if (!!data && !!data.data && (!!data.data.headerClose || !!data.data.bodyClose)) {
                this.beat();
            }

        }, () => {
            this.beat();
        });

        await modal.present();

    }

}
