import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ObservableCountService } from 'src/app/services/observable-count.service';
import { QUERYSTRING } from 'src/app/consts';

@Injectable({
	providedIn: 'root'
})
export class RouteService {

	private destination: any;

	constructor(public router: Router,
		public observableCountService: ObservableCountService) { }

	go(path: string, obj?: any) {
		this._navigateToPath(path, (!!obj ? obj : null));
	}

	private _navigateToPath(path: string, obj?: any): void {

		const params = ['/' + path];

		let querystring = {};

		let fragment = null;

		if (!!obj) {

			Object.keys(obj).forEach((key, i) => {

				if (key === QUERYSTRING) {
					querystring = obj[key];
				} else {

					if (key === 'fragment') {
						fragment = obj[key];
					} else {
						params.push(obj[key]);
					}

				}

			});

		}

		this.observableCountService.reset();

		if (Object.keys(querystring).length > 0) {
			this.router.navigate(params, { queryParams: querystring });
		} else {

			if (!!fragment) {
				this.router.navigate(params, { fragment: fragment });
			} else {
				this.router.navigate(params);
			}

		}

	}

}
