import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class PathService {

	private domain: string;
    private mediaDomain: string;
	private servicePathPrefix: string;
	private staticPathPrefix: string;

	constructor() {

        this.domain = 'https://uat-cms.knowvote.com/';
		// this.domain = 'http://192.168.0.76/';
		// this.domain = 'http://10.0.0.242/';

        this.mediaDomain = 'https://uat-kdc.knowvote.com/';
        // this.mediaDomain = '';

		this.servicePathPrefix = this.domain + 'services/';
		this.staticPathPrefix = this.domain + 'assets/json/';

	}

	get dom() {
		return this.domain;
	}

    get media() {
        return this.mediaDomain;
    }

	getPath(service: string) {
		return this.servicePathPrefix + service;
	}

	getStaticPath(fileName: string) {
		return this.staticPathPrefix + fileName;
	}

}
