import { Injectable } from '@angular/core';
import { PathService } from 'src/app/services/path.service';
import { LoadingService } from 'src/app/services/loading.service';
import { StorageService } from 'src/app/services/storage.service';
import { RouteService } from 'src/app/services/route.service';
import { AppstateService } from 'src/app/services/appstate.service';
import { HeartbeatService } from 'src/app/services/heartbeat.service';
import { ObservableCountService } from 'src/app/services/observable-count.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AlertController, LoadingController } from '@ionic/angular';
import { Observable, throwError, pipe, of } from 'rxjs';
import { catchError, map, last, tap } from 'rxjs/operators';
import { UNAUTHORIZED, FORBIDDEN } from 'src/app/consts';

@Injectable({
	providedIn: 'root'
})
export class HttpService {

	private showError: boolean;
	private errorMap: any;
	private observableCount: number;
	private dl: string;
	private isLoading: boolean;

	constructor(public http: HttpClient,
		public alertController: AlertController,
		public loadingController: LoadingController,
		public pathService: PathService,
		public loadingService: LoadingService,
		public storageService: StorageService,
		public routeService: RouteService,
		public appStateService: AppstateService,
        public heartbeatService: HeartbeatService,
		public observableCountService: ObservableCountService) {

		this.showError = true;
		this.errorMap = {
			default: {
				header: 'Something Went Wrong',
				message: 'Please try again later.',
				buttons: ['OK']
			},
			dbError: {
				header: 'Something Went Wrong',
				message: 'Please try again later.',
				buttons: ['OK']
			},
			unauthorized: {
				header: 'Please Log In',
				message: 'You\'ll need to be logged in to your account to continue.',
				buttons: ['OK']
			},
			invalidLogin: {
				header: 'Invalid Login',
				message: 'The combination of your e-mail address and password was not valid.<br />Please try again.',
				buttons: ['OK']
			},
			forbidden: {
				header: 'Restricted Access',
				message: 'Your profile is not permitted to view this content.',
				buttons: ['OK']
			}
		};

		this.dl = '';
		this.isLoading = false;

	}

	request(method: string,
		url: string,
		body?: any,
		suppressError?: boolean,
		showGlobalSpinner?: boolean): Observable<any> {

		let header = new HttpHeaders();
		const accessToken = this.storageService.get('access');

		// remove altBody once off of localhost
		const altBody = (!!body) ? body : {};

		if (!!accessToken) {
			header = header.set('Authorization', accessToken);
			altBody.authorization = accessToken;
		}

		this.showError = !suppressError;

		this.showLoading(showGlobalSpinner);

		/* return this.http.request(method, this.pathService.getPath(url), {
			body: altBody,
			headers: header,
			observe: 'response'
		}).pipe(
			map(this.handleSuccess.bind(this)),
			last(),
			catchError(this.handleError.bind(this))
		); */

		return this.http.request(method, this.pathService.getPath(url), {
			body: altBody,
			headers: header,
			observe: 'response'
		});

	}

	validateLogin(dl: string): Observable<any> {

		let header = new HttpHeaders();
		const accessToken = this.storageService.get('access');

		if (!!accessToken) {
			header = header.set('Authorization', accessToken);
		}

		this.showError = false;

		this.showLoading(true);

		this.dl = dl;

		return this.http.request('post', this.pathService.getPath('checkLogin'), {
			body: {
				authorization: accessToken
			},
			observe: 'response'
		}).pipe(
			map(this.handleValidate.bind(this)),
			last(),
			catchError(this.handleNonValidate.bind(this))
		);

	}

	handleValidate(res: any) {

		this.hideSpinner();

		if (!!res.body.access) {
			this.storageService.set('access', res.body.access);
		}

		return true;

	}

	handleNonValidate(err: any) {

		this.hideSpinner();

		this.storageService.remove('access');

        this.heartbeatService.clear();

		this.createAlert(UNAUTHORIZED);

		if (this.dl !== '') {
			this.routeService.go('login', { querystring: { dl: this.dl.substring(1) } });
		} else {
			this.routeService.go('login');
		}

		return of(false);

	}

	requestStaticJson(url: string,
		suppressError?: boolean,
		showGlobalSpinner?: boolean): Observable<any> {

		let header = new HttpHeaders();
		const accessToken = this.storageService.get('access');

		if (!!accessToken) {
			header = header.set('Authorization', accessToken);
		}

		this.showError = !suppressError;

		this.showLoading(showGlobalSpinner);

		/* return this.http.get('/assets/json/' + url, {
			headers: header,
			observe: 'response'
		}).pipe(
			map(this.handleSuccess.bind(this)),
			last(),
			catchError(this.handleError.bind(this))
		); */

		return this.http.get('/assets/json/' + url, {
			headers: header,
			observe: 'response'
		});

	}

	requestExternalJson(url: string,
		suppressError?: boolean,
		showGlobalSpinner?: boolean): Observable<any> {

		let header = new HttpHeaders();
		header = header.set('Accept', 'application/json, text/plain, */*');

		this.showError = !suppressError;

		this.showLoading(showGlobalSpinner);

		return this.http.get(url, {
			headers: header,
			observe: 'response'
		}).pipe(
			map(this.handleSuccess.bind(this)),
			last(),
			catchError(this.handleError.bind(this))
		);

	}

	requestPdf(url: string,
		body?: any,
		suppressError?: boolean,
		showGlobalSpinner?: boolean): Observable<any> {

		let header = new HttpHeaders();
		header = header.set('Accept', 'application/pdf, text/plain, */*');

		const accessToken = this.storageService.get('access');

		// remove altBody once off of localhost
		const altBody = (!!body) ? body : {};

		if (!!accessToken) {
			header = header.set('Authorization', accessToken);
			altBody.authorization = accessToken;
		}

		this.showError = !suppressError;

		this.showLoading(showGlobalSpinner);

		return this.http.request('post', this.pathService.getPath(url), {
			body: altBody,
			headers: header,
			responseType: 'blob'
		}).pipe(
			map(this.handleSuccess.bind(this)),
			last(),
			catchError(this.handleError.bind(this))
		);

	}

	handleSuccess(res: any): Observable<Object> {

		this.hideSpinner();

		//return of(res.body || {});
		return of(res);

	}

	handleError(error: any): Observable<Object> {

		let errMsg = 'default';

		this.hideSpinner();

		if (!!error.status) {

			if (error.status === 401) {

				if (!!error.url && error.url.indexOf('services/login') >= 0) {
					errMsg = 'invalidLogin';
				} else {
					errMsg = UNAUTHORIZED;
				}

			} else {

				if (error.status === 403) {
					errMsg = FORBIDDEN;
				} else {

					if (!!error.error && !!error.error.error) {
						errMsg = error.error.error;
					}

				}

			}

		} else {

			if (error instanceof HttpErrorResponse) {
				errMsg = error.error.error;
			}

		}

		(this.showError) ? this.createAlert(errMsg) : console.log(errMsg);

		return throwError(errMsg);

	}

	async createAlert(errMsg: string) {

		const alert = await this.alertController.create(this.errorMap[errMsg] || this.errorMap['default']);
		return await alert.present();

	}

	showLoading(showGlobalSpinner: boolean) {

		if (!!showGlobalSpinner) {

			this.loadingController.getTop().then((value: HTMLIonLoadingElement) => {

				if (!value && this.observableCountService.getCount() <= 0) {
					this._displaySpinner();
				}

			}, (err: any) => { });

		}

	}

	private async _displaySpinner() {

		this.observableCountService.increment();

		const loading = await this.loadingController.create({
			message: 'Please Wait...',
			duration: 500
		});

		await loading.present();

		this.isLoading = true;

	}

	hideSpinner(): void {

		this.observableCountService.decrement();

		if (this.observableCountService.getCount() <= 0) {

			let spinnerInterval;

			spinnerInterval = setInterval(() => {

				if (!!this.isLoading) {

					clearInterval(spinnerInterval);
					this.loadingController.dismiss().then((value) => {
						this.isLoading = false;
					}, (err: any) => { });

				}

			}, 50);

		}

	}

}
